import ajax from '../../common/services/httpService';
const BASE_URL = 'shift';

/**
 * @typedef {{
 * arrivals_percent: Number;
 * arrivals_percent_no_visits: Number;
 * complaints_percent: Number;
 * complaints_percent_no_visits: Number;
 * day_arrivals: Number;
 * end_hour: Number;
 * id: Number;
 * max_sla: Number;
 * min_sla: Number;
 * save_arrivals: Number;
 * shift_name: String;
 * shift_number: Number;
 * sla_percent: Number;
 * sla_percent_no_visits: Number;
 * start_hour: Number;
 * week_arrivals: String; //stringified array
 * }} Shift
 */

/**
 * @param {String | Number} site_id
 * @param {String | Number} [room_id]
 * @returns {Promise<Shift[]>}*/
async function getShifts(site_id, room_id) {
    const query = { site_id }
    if (room_id) query.room_id = room_id
    /**@type {Shift[]} */
    const shiftParams = await ajax.get(BASE_URL, null, query)
    const localShifts = shiftParams.map(shift => {
        const start = new Date()
        const end = new Date()
        start.setHours(shift.start_hour)
        end.setHours(shift.end_hour)
        shift.start_hour = start.getHours()
        shift.end_hour = end.getHours()
        return shift;
    })
    return localShifts;
}

/** @param {Shift} shift */
async function saveShift(shift) {
    const start = new Date();
    const end = new Date();
    start.setHours(shift.start_hour)
    end.setHours(shift.end_hour)
    if (shift.id) {
        const res = await ajax.put(BASE_URL, shift);
        return res;
    } else {
        const res = await ajax.post(BASE_URL, shift);
        return res;
    }
}

/**
 * @param {Number} site_id
 * @returns {Promise<Shift>}
 */
async function getCurrentShift(site_id) {
    const shifts = await getShifts(site_id);
    const now = new Date();
    const currHour = now.getHours();
    const currDay = now.getDay();
    const currShift = shifts.find(({ start_hour, end_hour, week_arrivals }) => {
        const workingHours = _getShiftWorkingHours({ start_hour, end_hour })
        const isHourMatch = workingHours[currHour]
        const isDayMatch = JSON.parse(week_arrivals).includes(currDay + 1);
        return (isHourMatch && isDayMatch);
    });
    return currShift;
}

/**@param {String | Number} id */
async function removeShift(id,roomId = null) {
    const res = await ajax.delete(BASE_URL, { id, roomId })
    return res;
}

function _getShiftWorkingHours({ start_hour, end_hour }) {
    const workingHours = {}
    if (end_hour > start_hour) {
        for (let i = start_hour; i < end_hour; i++) {
            workingHours[i] = true
        }
    } else {
        for (let i = start_hour; i < 24; i++) {
            workingHours[i] = true
        }
        for (let i = 0; i < end_hour; i++) {
            workingHours[i] = true
        }
    }
    return workingHours
}

export default {
    getShifts,
    saveShift,
    removeShift,
    getCurrentShift
}